import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const MisluktPage = () => (
  <Layout>
    <SEO title="Mislukt" />
    <h1>De email is niet verzonden</h1>
    <p>Vul alle verplichte velden in.</p>
  </Layout>
)

export default MisluktPage
